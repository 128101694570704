@tailwind base;
@tailwind components;
@tailwind utilities;

.custome-btn {
	@apply py-1.5 px-10 font-thin ease-in duration-300 rounded-sm hover:bg-white/20 hover:backdrop-blur-sm bg-slate-900 text-white;
}
.banner-text-mobile {
	@apply md:hidden w-full text-center text-4xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-slate-900 to-orange-900 opacity-[1] absolute top-[20%];
}
.banner-text-desktop {
	@apply hidden md:inline-block md:text-[80px] lg:text-[100px] xl:text-[150px] 2xl:text-[170px] text-transparent bg-clip-text bg-gradient-to-r from-slate-900 to-orange-900 opacity-[1] absolute md:top-40 lg:top-[90px] 2xl:top-20;
}
.arrow-back {
	@apply text-black bg-white shadow-lg rounded-full text-lg md:text-2xl p-3 hover:md:bg-neutral-900 hover:md:text-white ease-in duration-300 cursor-pointer absolute right-5 md:right-10 2xl:right-16 top-[40px] md:top-[50px] z-10;
}
.select-date {
	@apply w-full text-xs text-center text-orange-700 bg-neutral-200 border-orange-700 mb-0 opacity-[1] ease-in duration-300;
}
.select-category {
	@apply block w-full px-3 py-2 text-sm font-thin placeholder-gray-200 border-none text-neutral-600 ring-0;
}

.selectable-day {
	color: #ccc !important;
	cursor: pointer;
}
