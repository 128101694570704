:root {
	/* COLORS  */
	/* primary */
	--primary-0: #f0efff;
	--primary-50: #d8d6ff;
	--primary-100: #c1beff;
	--primary-200: #aaa5ff;
	--primary-300: #938dff;
	--primary-400: #7b74ff;
	/* main */
	--primary-500: #645cff;
	--primary-600: #554ed9;
	--primary-700: #4640b3;
	--primary-800: #37338c;
	--primary-900: #282566;
	--primary-1000: #191740;
	--primary-1100: #0a0919;
	/* grey */
	--grey-0: #fafbfc;
	--grey-50: #f2f4f8;
	--grey-100: #eaedf3;
	--grey-200: #e2e7ef;
	--grey-300: #dbe0ea;
	--grey-400: #d3dae6;
	--grey-500: #cbd3e1;
	--grey-600: #adb3bf;
	--grey-700: #8e949e;
	--grey-800: #70747c;
	--grey-900: #51545a;
	--grey-1000: #333538;
	--grey-1100: #141516;
	/* Purple */
	--purple-0: #f5ecfe;
	--purple-100: #ebddfe;
	--purple-200: #e0cefe;
	--purple-300: #d6bffe;
	--purple-400: #cbaffe;
	--purple-500: #c1a0fe;
	--purple-600: #b691fe;
	--purple-700: #ac82fe;
	--purple-800: #a77afe;
	--purple-900: #a172fd;

	/* pink */
	--pink-100: #fff0f3;
	--pink-200: #ffccd5;
	--pink-300: #ffb3c1;
	--pink-400: #ff8fa3;
	--pink-500: #ff758f;
	--pink-600: #ff4d6d;
	--pink-700: #c9184a;
	--pink-800: #a4133c;
	--pink-900: #800f2f;
	--pink-1000: #590d22;

	/* rose gold */
	--roseGold-100: #d0a1a8;
	--roseGold-200: #c89098;
	--roseGold-300: #bf7f89;
	--roseGold-400: #b76e79;
	--roseGold-500: #af5d69;
	--roseGold-600: #a2505d;
	--roseGold-700: #914853;

	/* rest */
	--black: #222;
	--white: #fff;
	--red-light: #f8d7da;
	--red-dark: #842029;
	--green-light: #d1e7dd;
	--green-dark: #0f5132;

	/* box shadows */
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	--shadow-5: 0px 0px 10px rgba(0, 0, 0, 0.01),
		0 10px 10px -5px rgba(0, 0, 0, 0.03);
	--shadow-6: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0);
	/* typography */
	--defaultFontSize: 87.5%;
	--headingFont: "Noto Sans Display", monospace;
	--bodyFont: "Noto Sans Display", sans-serif;
	--smallText: 0.8em;
	--extraSmallText: 0.7em;
	/* rest */
	--backgroundColor: var(--grey-0);
	--textColor: var(--grey-1000);
	--borderRadius: 0.2rem;
	--borderRadius2: 0.8rem;
	--letterSpacing: 1px;
	--transition: 0.3s ease-in-out all;
	--max-width: 1520px;
	--fixed-width: 600px;
}

html {
	scroll-behavior: smooth;
}

.total-button {
	margin-top: 30px;
	display: flex;
	width: 290px;
	justify-content: center;
}

/* Shipping form style validation */
.user-email-input {
	width: 100%;
	margin-bottom: 10px;
	border-width: 0px;
}
.user-email-input::placeholder {
	font-style: italic;
	font-size: 12px;
	color: var(--grey-600);
}
.input-error {
	border-width: 1px;
	border-color: var(--red-dark);
}
.user-email-input-error {
	margin-top: 10px;
	font-size: 16px;
	color: var(--red-dark);
}
.email-verify {
	font-size: 16px;
	margin-top: 10px;
	color: var(--grey-600);
}
.total-button {
	margin-top: 30px;
	display: flex;
	width: 290px;
	justify-content: center;
}

.scanner h1 {
	color: yellow;
	position: absolute;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 30px;
}

.scanner h1:before {
	content: "Fetching PVG data...";
	position: absolute;
	top: 0;
	left: 0 !important;
	width: 0;
	height: 100%;
	border-right: 4px solid black;
	overflow: hidden;
	color: black;
	animation: load 4s ease infinite;
}

/* Shipment */
.top {
	max-width: 100%;
	margin: 0 auto;
}
.top > table {
	background-color: white;
}
.table-head-row {
	background-color: var(--grey-300);
	width: 100vw;
	margin: 0 auto;
}
.table-head-item {
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-right: 1px solid var(--grey-600);
	border-left: 1px solid var(--grey-600);
	text-transform: capitalize;
}
.table-item-row {
	position: relative;
	background-color: whitesmoke;
}
.table-items {
	border: 1px solid var(--grey-500);
	padding: 5px;
	text-align: center;
	color: var(--grey-800);
	cursor: pointer;
}
.align-left {
	text-align: left;
}
.table-items span {
	display: flex;
	justify-content: flex-end;
	color: var(--grey-1100);
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	text-transform: uppercase;
}
.table-items span:hover {
	color: var(--primary-600);
}
.table-items svg {
	display: inline;
	font-size: 24px;
}
.been-shipped {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	background-color: rgba(169, 169, 169, 0.95);
}
.been-shipped-textsm {
	font-size: 16px;
	text-transform: capitalize !important;
	font-weight: 400 !important;
}
.been-shipped button {
	color: var(--grey-700);
	text-transform: uppercase;
	cursor: not-allowed;
}
.been-shipped span {
	color: var(--black);
	text-transform: uppercase;
	font-weight: bold;
}
.been-shipped button:hover {
	color: var(--black);
}
.history-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	margin-left: 100px;
	max-width: 100%;
	overflow-x: auto;
}
.progress {
	height: 1px;
	width: 80%;
	margin: 0 auto;
	background-color: rgb(255, 255, 255);
	position: absolute;
	left: 10%;
	overflow: hidden;
}
.progress .inner {
	height: 100%;
	background-color: rgb(234 179 8);
	position: absolute;
	animation: progress 1s infinite;
}
.navStyle {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.navStyleChild {
	position: relative;
}
.navStyleChild::after {
	position: absolute;
	content: "";
	width: 0%;
	height: 1px;
	background-color: rgb(234 179 8);
	left: 50%;
	bottom: -2px;
	transition: all 0.3s ease-in-out;
}
.navStyleChild:hover::after {
	width: 100%;
	left: 0;
}
@keyframes progress {
	from {
		left: -5%;
		width: 5%;
	}
	to {
		left: 105%;
		width: 75%;
	}
}
@media screen and (min-width: 850px) {
	.top {
		margin-left: 0px;
		margin-right: 0px;
	}
	.history-page {
		/* max-width: 90%; */
		overflow-x: hidden;
	}
}
@media screen and (min-width: 1820px) {
	.history-page {
		/* max-width: 85%; */
		overflow-x: hidden;
	}
}
@media screen and (min-width: 2100px) {
	.history-page {
		/* max-width: 80%; */
	}
}
@media screen and (min-width: 2300px) {
	.history-page {
		/* max-width: 75%; */
	}
}
@keyframes load {
	0%,
	10%,
	100% {
		width: 0;
	}
	60%,
	80% {
		width: 100%;
	}
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.bg-blur {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background-color: rgba(255, 255, 255, 0.5);
	}
	.bg-blur2 {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: rgba(34, 34, 34, 0.9);
	}
	.bg-blur3 {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: rgba(234, 179, 8, 0.9);
	}
	.bg-blur4 {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: rgba(0, 0, 0, 0.9);
	}
	.bg-blur-faint {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: rgba(255, 255, 255, 0.2);
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.list::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* width height */
.featuredCard::-webkit-scrollbar {
	height: 10px;
	width: 9px;
}
.slimBar::-webkit-scrollbar {
	height: 5px;
}

/* Track */
.featuredCard::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(212, 212, 212);
	border-radius: 100px;
}

/* Handle */
.featuredCard::-webkit-scrollbar-thumb {
	background: rgba(219, 219, 219, 1);
	border-radius: 100px;
}
.slimBar::-webkit-scrollbar-thumb {
	background: rgba(50, 50, 50, 0.4);
	border-radius: 100px;
}

/* Handle on hover */
.featuredCard::-webkit-scrollbar-thumb:hover {
	background: rgba(50, 50, 50, 0.4);
}
/* width height */
.featuredCard2::-webkit-scrollbar {
	height: 10px;
	width: 8px;
}
/* Handle on hover */
.featuredCard2::-webkit-scrollbar-thumb {
	background: rgba(43, 43, 43, 0.8);
	border-radius: 100px;
}

/* Handle on hover */
.featuredCard2::-webkit-scrollbar-thumb:hover {
	background: rgba(78, 78, 78, 0.8);
}

/* Scroll to top button */
.scrollToTopBtn {
	width: 50px;
	height: 50px;
	display: block;
	position: fixed;
	font-size: 18px;
	border: none;
	outline: none;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	cursor: pointer;
	padding: 15px;
	border-radius: 4px;
	transition: all 0.5s ease;
}
.pagWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.flipArrow {
	transform: rotate(180deg);
}
.pagButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 0 5px;
	font-size: 28px;
	background-color: #fff;
	border: 1px solid rgb(0, 0, 0);
	color: rgb(134, 134, 134);
	border-radius: 50px;
	cursor: pointer;
	transition: background 0.5s ease;
}
@media (max-width: 500px) {
	.pagButton {
		width: 35px;
		height: 35px;
	}
}
.pagNum {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	color: rgb(193, 193, 193);
}
.active {
	color: rgb(0, 0, 0);
	transition: background 0.5s ease;
}

.sticky-position {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}
